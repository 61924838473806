<mat-label *ngIf="!titleClass">Comments</mat-label>
<div *ngIf="titleClass" [class]="titleClass">Comments</div>

<div class="flex flex-col w-full mt-4" style="gap: 1rem">
  <ng-container *ngFor="let comment of comments; let i = index;">
    <div [id]="'comment-' + i">
      <div class="flex flex-row" [class.editing-comment]="currentId == comment.id">

        <!-- Avatar -->
        <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden mb-2 mr-2">
          <ng-container *ngIf="getAvatar(comment.userId)">
              <img
                  class="object-cover w-full h-full"
                  [src]="getAvatar(comment.userId)"
                  alt="User avatar"/>
          </ng-container>
          <ng-container *ngIf="!getAvatar(comment.userId)">
              <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                  {{GetUserName(comment.userId).charAt(0)}}
              </div>
          </ng-container>
        </div>


        <div class="flex-1">
          <div class="font-bold">
            {{ GetUserName(comment.userId) | titlecase }}
          </div>

          <div class="italic whitespace-pre-line">
            {{ comment.text }}
          </div>
          <div class="text-sm mt-2" style="color: black;">
            {{ comment.timeStamp | date:'d MMM yyyy, h:mm a' }}<!-- by {{ GetUserName(comment.userId) | titlecase }}-->
          </div>
        </div>
        <button
          *ngIf="canEditComment(comment)"
          mat-icon-button
          class="w-8 h-8 min-h-8"
          style="margin-right: -4px;"
          (click)="openCommentEdit(comment)"
          >
          <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
        </button>
        <button
          *ngIf="canEditComment(comment)"
          mat-icon-button
          class="w-8 h-8 min-h-8"
          (click)="deleteComment(comment.id)"
          >
          <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  
  <mat-form-field
    class="w-full mt-2"
    [subscriptSizing]="'dynamic'">
    <textarea
      matInput
      [(ngModel)]="currentText"
      [placeholder]="'Add a comment...'"
      [rows]="2"
      cdkTextareaAutosize
      [cdkAutosizeMinRows]="1">
    </textarea>
  </mat-form-field>

  <button
    *ngIf="!editingComment"
    mat-flat-button
    style="width: fit-content;"
    [color]="'primary'"
    [disabled]="currentText.trim() === ''"
    (click)="saveButtonClicked()">
    <!-- <mat-icon class="text-hint" [svgIcon]="'heroicons_outline:plus'"></mat-icon> -->
    <span class="ml-1">{{ editingComment ? 'Save' : 'Add' }} Comment</span>
  </button>
  <div *ngIf="editingComment" class="flex flex-row justify-end" style="margin-top: -0.75rem; height: 52px;">
    <button
      mat-icon-button
      class="w-8 h-8 min-h-8"
      style="margin-right: -4px;"
      (click)="wipeComment()">
      <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:cancel'"></mat-icon>
    </button>
    <button
      mat-icon-button
      class="w-8 h-8 min-h-8"
      (click)="saveButtonClicked()">
      <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:check-circle'" style="color: var(--fuse-primary) !important;"></mat-icon>
    </button>
  </div>
</div>
