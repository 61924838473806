import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { initialDataResolver } from './app.resolvers';
import { NoAuthGuard } from './api/services/cookie-jwt-auth/guards/noAuth.guard';
import { AuthGuard } from './api/services/cookie-jwt-auth/guards/auth.guard';
import { FeatureGuard } from './api/services/cookie-jwt-auth/guards/feature-guard';
import { BasicGuard } from './api/services/cookie-jwt-auth/guards/basic-guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed-in user to the '/dashboard'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'email-confirmed', loadChildren: () => import('app/views/auth/email-confirmed/email-confirmed.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/views/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/views/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/views/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/views/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/views/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/views/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', 
            loadChildren: () =>
                import("app/views/landing/home/home.module").then(
                (m) => m.HomeModule
            )},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            // Profile
            {
                path: 'profile', loadChildren: () => //import('app/views/admin/dashboard/dashboard.routes')
                    import("app/views/admin/profile/profile.module").then(
                    (m) => m.ProfileModule
                ),
            },

            // Dashboard
            {
                path: 'dashboard', loadChildren: () => //import('app/views/admin/dashboard/dashboard.routes')
                    import("app/views/admin/dashboard/dashboard.module").then(
                    (m) => m.DashboardModule
                ),
            },


            {
                path: 'customers',
                loadChildren: () =>
                    import("app/views/admin/apps/contacts/contacts.module").then(
                    (m) => m.ContactsModule
                ),
            },

            {
                path: 'drivers',  loadChildren: () =>
                    import("app/views/admin/erp/hr/hr.module").then(
                    (m) => m.HrModule
                ),
            },

            {
                path: 'drivers-schedule',  loadChildren: () =>
                    import("app/views/admin/pages/workload/workload.module").then(
                    (m) => m.WorkloadModule
                ),
            },

            {
                path: 'jobs', loadChildren: () =>
                    import("app/views/admin/jobs/jobs.module").then(
                    (m) => m.JobsModule
                ),
            },

            {
                path: 'wages',loadChildren: () =>
                    import("app/views/admin/wages/wages.module").then(
                    (m) => m.WagesModule
                ),
            },

            {
                path: 'invoices',loadChildren: () =>
                    import("app/views/admin/pages/invoice/invoice.module").then(
                    (m) => m.InvoiceModule
                ),
            },

            {
                path: 'dailyjobsheet',loadChildren: () =>
                    import("app/views/admin/daily-job-sheet/daily-job-sheet.module").then(
                    (m) => m.DailyJobSheetModule
                ),
            },



            // CRM
            {path: 'crm', canActivate: [FeatureGuard], canActivateChild: [FeatureGuard], children: [
                {path: 'dashboard',
                    loadChildren: () =>
                        import("app/views/admin/crm/crm-dashboard/crm-dashboard.module").then(
                        (m) => m.CrmDashboardModule
                    ),
                },
                {path: 'contacts',
                    loadChildren: () =>
                        import("app/views/admin/apps/contacts/contacts.module").then(
                        (m) => m.ContactsModule
                    ),
                },
                {path: 'leads',
                    loadChildren: () =>
                        import("app/views/admin/crm/leads-management/leads-management.module").then(
                        (m) => m.LeadsManagementModule
                    ),
                },
                {path: 'pipeline',
                    loadChildren: () =>
                        import("app/views/admin/crm/pipeline/sales-pipeline.module").then(
                        (m) => m.SalesPipelineModule
                    ),
                },
                {path: 'emails',
                    loadChildren: () =>
                        import("app/views/admin/apps/mailbox/mailbox.module").then(
                        (m) => m.MailboxModule
                    ),
                },
                {path: 'reporting'},
            ]},


            // Project Management
            {path: 'project-management', canActivate: [FeatureGuard], canActivateChild: [FeatureGuard], children: [
                {path: 'dashboard', 
                    loadChildren: () =>
                        import("app/views/admin/pages/project-dashboard/project-dashboard.module").then(
                        (m) => m.ProjectDashboardModule
                    ),
                },
                {path: 'projects', 
                    loadChildren: () =>
                        import("app/views/admin/pages/projects/projects.module").then(
                        (m) => m.ProjectsModule
                    ),
                },
                {path: 'workload', 
                    loadChildren: () =>
                        import("app/views/admin/pages/workload/workload.module").then(
                        (m) => m.WorkloadModule
                    ),
                },
                {path: 'invoicing',
                    loadChildren: () =>
                        import("app/views/admin/pages/invoice/invoice.module").then(
                        (m) => m.InvoiceModule
                    ),
                },
                {path: 'purchase-orders',
                    loadChildren: () =>
                        import("app/views/admin/pages/purchase-orders/purchase-orders.module").then(
                        (m) => m.PurchaseOrdersModule
                    ),
                },
                {path: 'reporting'},
            ]},


            // Quotation Form
            {path: 'quotation-form', canActivate: [FeatureGuard], canActivateChild: [FeatureGuard],
                loadChildren: () =>
                    import("app/views/admin/pages/quotation-form/quotation-form.module").then(
                    (m) => m.QuotationFormModule
                )
            },


            // ERP
            {path: 'erp', canActivate: [FeatureGuard], canActivateChild: [FeatureGuard], children: [
                {path: 'contacts',
                    loadChildren: () =>
                        import("app/views/admin/apps/contacts/contacts.module").then(
                        (m) => m.ContactsModule
                    ),
                },
                {path: 'products',
                    loadChildren: () =>
                        import("app/views/admin/erp/products/products.module").then(
                        (m) => m.ProductsModule
                    ),
                },
                {path: 'sales'},
                {path: 'hr',
                    loadChildren: () =>
                        import("app/views/admin/erp/hr/hr.module").then(
                        (m) => m.HrModule
                    ),
                },
                {path: 'purchases'},
                {path: 'invoicing',
                    loadChildren: () =>
                        import("app/views/admin/pages/invoice/invoice.module").then(
                        (m) => m.InvoiceModule
                    ),
                },
                {path: 'grn'},
                {path: 'production'},
                {path: 'leads',
                    loadChildren: () =>
                        import("app/views/admin/crm/leads-management/leads-management.module").then(
                        (m) => m.LeadsManagementModule
                    ),
                },
                {path: 'pipeline',
                    loadChildren: () =>
                        import("app/views/admin/crm/pipeline/sales-pipeline.module").then(
                        (m) => m.SalesPipelineModule
                    ),
                },
                {path: 'suppliers',
                    data: { erp: true },
                    loadChildren: () =>
                        import("app/views/admin/installation/suppliers/suppliers.module").then(
                        (m) => m.SuppliersModule
                    ),
                },
                {path: 'reporting'},
            ]},


            // Installation & Servicing
            {path: 'installation', canActivate: [FeatureGuard], canActivateChild: [FeatureGuard], children: [
                {path: 'contacts',
                    loadChildren: () =>
                        import("app/views/admin/apps/contacts/contacts.module").then(
                        (m) => m.ContactsModule
                    ),
                },
                {path: 'products',
                    loadChildren: () =>
                        import("app/views/admin/erp/products/products.module").then(
                        (m) => m.ProductsModule
                    ),
                },
                {path: 'purchase-orders',
                    loadChildren: () =>
                        import("app/views/admin/pages/purchase-orders/purchase-orders.module").then(
                        (m) => m.PurchaseOrdersModule
                    ),
                },
                {path: 'suppliers',
                    data: { erp: false },
                    loadChildren: () =>
                        import("app/views/admin/installation/suppliers/suppliers.module").then(
                        (m) => m.SuppliersModule
                    ),
                },                
                {path: 'invoicing',
                    loadChildren: () =>
                        import("app/views/admin/pages/invoice/invoice.module").then(
                        (m) => m.InvoiceModule
                    ),
                },
                {path: 'calendar',
                    loadChildren: () =>
                        import("app/views/admin/apps/calendar/calendar.module").then(
                        (m) => m.CalendarModule
                    ),
                },
                {path: 'documentations',
                    loadChildren: () =>
                    import("app/views/admin/installation/documentations/documentations.module").then(
                    (m) => m.DocumentationsModule
                    ),
                },
            ]},


            // Ecommerce
            {path: 'eCommerce', canActivate: [FeatureGuard], canActivateChild: [FeatureGuard], children: [
                {path: 'inventory',
                    loadChildren: () =>
                        import("app/views/admin/apps/ecommerce/ecommerce.module").then(
                        (m) => m.EcommerceModule
                    ),
                },
                {path: 'stock'},
                {path: 'sales-history'},
            ]},


            // Misc Apps
            {path: 'apps', canActivateChild: [FeatureGuard], children: [
                {path: 'calendar',
                    loadChildren: () =>
                        import("app/views/admin/apps/calendar/calendar.module").then(
                        (m) => m.CalendarModule
                    ),
                },
                {path: 'mailbox',
                    loadChildren: () =>
                        import("app/views/admin/apps/mailbox/mailbox.module").then(
                        (m) => m.MailboxModule
                    ),
                },
                {path: 'messenger'},
                {path: 'courses'},
                {path: 'tasks'},
                {path: 'files'},
            ]},

            
            // Settings
            {path: 'settings', canActivate: [BasicGuard], canActivateChild: [BasicGuard],
                loadChildren: () =>
                    import("app/views/admin/pages/settings/settings.module").then(
                    (m) => m.SettingsModule
                ),
            },

            // 404 & Catch all
            {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
