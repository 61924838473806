/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { DriverDtoBaseResponse } from '../models/driver-dto-base-response';
import { DriverListDto } from '../models/driver-list-dto';
import { DriverListDtoBaseResponse } from '../models/driver-list-dto-base-response';
import { DriverListDtoListBaseResponse } from '../models/driver-list-dto-list-base-response';
import { DriverListDtoPagedResponseBaseResponse } from '../models/driver-list-dto-paged-response-base-response';
import { JobListDtoListBaseResponse } from '../models/job-list-dto-list-base-response';
import { PaginationRequest } from '../models/pagination-request';

@Injectable({
  providedIn: 'root',
})
export class DriverService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiDriverListDriversPost
   */
  static readonly ApiDriverListDriversPostPath = '/api/Driver/list-drivers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverListDriversPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverListDriversPost$Plain$Response(params?: {
    body?: PaginationRequest
  }): Observable<StrictHttpResponse<DriverListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverListDriversPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverListDriversPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverListDriversPost$Plain(params?: {
    body?: PaginationRequest
  }): Observable<DriverListDtoPagedResponseBaseResponse> {

    return this.apiDriverListDriversPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DriverListDtoPagedResponseBaseResponse>) => r.body as DriverListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverListDriversPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverListDriversPost$Json$Response(params?: {
    body?: PaginationRequest
  }): Observable<StrictHttpResponse<DriverListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverListDriversPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverListDriversPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverListDriversPost$Json(params?: {
    body?: PaginationRequest
  }): Observable<DriverListDtoPagedResponseBaseResponse> {

    return this.apiDriverListDriversPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DriverListDtoPagedResponseBaseResponse>) => r.body as DriverListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiDriverAllListDriversGet
   */
  static readonly ApiDriverAllListDriversGetPath = '/api/Driver/all-list-drivers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverAllListDriversGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverAllListDriversGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<DriverListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverAllListDriversGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverAllListDriversGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverAllListDriversGet$Plain(params?: {
  }): Observable<DriverListDtoListBaseResponse> {

    return this.apiDriverAllListDriversGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DriverListDtoListBaseResponse>) => r.body as DriverListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverAllListDriversGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverAllListDriversGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<DriverListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverAllListDriversGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverAllListDriversGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverAllListDriversGet$Json(params?: {
  }): Observable<DriverListDtoListBaseResponse> {

    return this.apiDriverAllListDriversGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DriverListDtoListBaseResponse>) => r.body as DriverListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiDriverAllJobsForDriverGet
   */
  static readonly ApiDriverAllJobsForDriverGetPath = '/api/Driver/all-jobs-for-driver';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverAllJobsForDriverGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverAllJobsForDriverGet$Plain$Response(params?: {
    driverId?: number;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverAllJobsForDriverGetPath, 'get');
    if (params) {
      rb.query('driverId', params.driverId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverAllJobsForDriverGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverAllJobsForDriverGet$Plain(params?: {
    driverId?: number;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiDriverAllJobsForDriverGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverAllJobsForDriverGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverAllJobsForDriverGet$Json$Response(params?: {
    driverId?: number;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverAllJobsForDriverGetPath, 'get');
    if (params) {
      rb.query('driverId', params.driverId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverAllJobsForDriverGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverAllJobsForDriverGet$Json(params?: {
    driverId?: number;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiDriverAllJobsForDriverGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiDriverJobsForDriverInDateGet
   */
  static readonly ApiDriverJobsForDriverInDateGetPath = '/api/Driver/jobs-for-driver-in-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverJobsForDriverInDateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverJobsForDriverInDateGet$Plain$Response(params?: {
    driverId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverJobsForDriverInDateGetPath, 'get');
    if (params) {
      rb.query('driverId', params.driverId, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverJobsForDriverInDateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverJobsForDriverInDateGet$Plain(params?: {
    driverId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiDriverJobsForDriverInDateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverJobsForDriverInDateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverJobsForDriverInDateGet$Json$Response(params?: {
    driverId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverJobsForDriverInDateGetPath, 'get');
    if (params) {
      rb.query('driverId', params.driverId, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverJobsForDriverInDateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverJobsForDriverInDateGet$Json(params?: {
    driverId?: number;
    startDate?: string;
    endDate?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiDriverJobsForDriverInDateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiDriverDriverGet
   */
  static readonly ApiDriverDriverGetPath = '/api/Driver/driver';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverDriverGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverGet$Plain$Response(params?: {
    driverId?: number;
  }): Observable<StrictHttpResponse<DriverDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverDriverGetPath, 'get');
    if (params) {
      rb.query('driverId', params.driverId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverDriverGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverGet$Plain(params?: {
    driverId?: number;
  }): Observable<DriverDtoBaseResponse> {

    return this.apiDriverDriverGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DriverDtoBaseResponse>) => r.body as DriverDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverDriverGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverGet$Json$Response(params?: {
    driverId?: number;
  }): Observable<StrictHttpResponse<DriverDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverDriverGetPath, 'get');
    if (params) {
      rb.query('driverId', params.driverId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverDriverGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverGet$Json(params?: {
    driverId?: number;
  }): Observable<DriverDtoBaseResponse> {

    return this.apiDriverDriverGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DriverDtoBaseResponse>) => r.body as DriverDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDriverDriverPut
   */
  static readonly ApiDriverDriverPutPath = '/api/Driver/driver';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverDriverPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverDriverPut$Plain$Response(params?: {
    body?: DriverListDto
  }): Observable<StrictHttpResponse<DriverListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverDriverPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverDriverPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverDriverPut$Plain(params?: {
    body?: DriverListDto
  }): Observable<DriverListDtoBaseResponse> {

    return this.apiDriverDriverPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DriverListDtoBaseResponse>) => r.body as DriverListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverDriverPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverDriverPut$Json$Response(params?: {
    body?: DriverListDto
  }): Observable<StrictHttpResponse<DriverListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverDriverPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverDriverPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverDriverPut$Json(params?: {
    body?: DriverListDto
  }): Observable<DriverListDtoBaseResponse> {

    return this.apiDriverDriverPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DriverListDtoBaseResponse>) => r.body as DriverListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDriverDriverPost
   */
  static readonly ApiDriverDriverPostPath = '/api/Driver/driver';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverDriverPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverDriverPost$Plain$Response(params?: {
    body?: DriverListDto
  }): Observable<StrictHttpResponse<DriverListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverDriverPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverDriverPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverDriverPost$Plain(params?: {
    body?: DriverListDto
  }): Observable<DriverListDtoBaseResponse> {

    return this.apiDriverDriverPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DriverListDtoBaseResponse>) => r.body as DriverListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverDriverPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverDriverPost$Json$Response(params?: {
    body?: DriverListDto
  }): Observable<StrictHttpResponse<DriverListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverDriverPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverDriverPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiDriverDriverPost$Json(params?: {
    body?: DriverListDto
  }): Observable<DriverListDtoBaseResponse> {

    return this.apiDriverDriverPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DriverListDtoBaseResponse>) => r.body as DriverListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDriverDriverForUserGet
   */
  static readonly ApiDriverDriverForUserGetPath = '/api/Driver/driver-for-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverDriverForUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverForUserGet$Plain$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<DriverDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverDriverForUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverDriverForUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverForUserGet$Plain(params?: {
    userId?: number;
  }): Observable<DriverDtoBaseResponse> {

    return this.apiDriverDriverForUserGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DriverDtoBaseResponse>) => r.body as DriverDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverDriverForUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverForUserGet$Json$Response(params?: {
    userId?: number;
  }): Observable<StrictHttpResponse<DriverDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverDriverForUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverDriverForUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverForUserGet$Json(params?: {
    userId?: number;
  }): Observable<DriverDtoBaseResponse> {

    return this.apiDriverDriverForUserGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DriverDtoBaseResponse>) => r.body as DriverDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDriverDriverListItemGet
   */
  static readonly ApiDriverDriverListItemGetPath = '/api/Driver/driver-list-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverDriverListItemGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverListItemGet$Plain$Response(params?: {
    driverId?: number;
  }): Observable<StrictHttpResponse<DriverListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverDriverListItemGetPath, 'get');
    if (params) {
      rb.query('driverId', params.driverId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverDriverListItemGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverListItemGet$Plain(params?: {
    driverId?: number;
  }): Observable<DriverListDtoBaseResponse> {

    return this.apiDriverDriverListItemGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DriverListDtoBaseResponse>) => r.body as DriverListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverDriverListItemGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverListItemGet$Json$Response(params?: {
    driverId?: number;
  }): Observable<StrictHttpResponse<DriverListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverDriverListItemGetPath, 'get');
    if (params) {
      rb.query('driverId', params.driverId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriverListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverDriverListItemGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverDriverListItemGet$Json(params?: {
    driverId?: number;
  }): Observable<DriverListDtoBaseResponse> {

    return this.apiDriverDriverListItemGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DriverListDtoBaseResponse>) => r.body as DriverListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiDriverToggleArchiveDriverDelete
   */
  static readonly ApiDriverToggleArchiveDriverDeletePath = '/api/Driver/toggle-archive-driver';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverToggleArchiveDriverDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverToggleArchiveDriverDelete$Plain$Response(params?: {
    driverId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverToggleArchiveDriverDeletePath, 'delete');
    if (params) {
      rb.query('driverId', params.driverId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverToggleArchiveDriverDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverToggleArchiveDriverDelete$Plain(params?: {
    driverId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiDriverToggleArchiveDriverDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDriverToggleArchiveDriverDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverToggleArchiveDriverDelete$Json$Response(params?: {
    driverId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DriverService.ApiDriverToggleArchiveDriverDeletePath, 'delete');
    if (params) {
      rb.query('driverId', params.driverId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiDriverToggleArchiveDriverDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDriverToggleArchiveDriverDelete$Json(params?: {
    driverId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiDriverToggleArchiveDriverDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
