<div style="width: 25vw; min-width: 300px;">
    <form [formGroup]="addItemForm">
        
        <!-- Header -->
        <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary" style="margin: -24px;">
            <div class="text-lg font-medium">{{title}}</div>
            <button
                mat-icon-button
                (click)="close()"
                [tabIndex]="-1">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-4">
            
            <div class="sm:col-span-4 grid sm:grid-cols-4 gap-x-6 gap-y-4 w-full mt-8">
                <ng-container *ngFor="let configItem of formConfig; let i = index;">
                    <div [id]="'configItem-' + i" class="sm:col-span-4">
                        <mat-form-field
                            class="w-full fuse-mat-dense"
                            [subscriptSizing]="'dynamic'">
                            <mat-label>{{configItem.name | titlecase}}</mat-label>

                            <mat-icon
                                *ngIf="configItem.icon"
                                class="icon-size-5"
                                [svgIcon]="configItem.icon"
                                matPrefix></mat-icon>

                            <input
                                *ngIf="configItem.type != 'date' && configItem.type != 'textarea' && configItem.type != 'select'"
                                [formControlName]="getFormControlName(configItem)"
                                matInput>
                                
                            <textarea
                                *ngIf="configItem.type == 'textarea'"
                                [formControlName]="getFormControlName(configItem)"
                                cdkAutosizeMinRows="3"
                                cdkTextareaAutosize
                                matInput></textarea>
                                
                            <span *ngIf="configItem.type == 'percentage'" matSuffix>%</span>

                            <ng-container *ngIf="configItem.type == 'date'">
                                <input
                                    [formControlName]="getFormControlName(configItem)"
                                    [matDatepicker]="picker2"
                                    matInput>
                                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </ng-container>

                            <mat-select *ngIf="configItem.type == 'select'"
                                [formControlName]="getFormControlName(configItem)">
                                <ng-container *ngFor="let option of configItem.options">
                                    <mat-option [value]="option.value">{{option.name}}</mat-option>
                                </ng-container>
                            </mat-select>

                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- Divider -->
        <div class="mt-11 mb-10 border-t"></div>

        <!-- Actions -->
        <div class="flex flex-row items-between w-full">
            <button 
                *ngIf="showDeleteButton"
                (click)="deleteItem()"
                mat-stroked-button
                type="button"
                [color]="'warn'">
                Delete
            </button>

            <div class="flex items-center justify-end ml-auto">
                <button
                    (click)="close()"
                    mat-stroked-button
                    type="button">
                    Cancel
                </button>
                <button
                    (click)="save()"
                    class="ml-4"
                    mat-flat-button
                    type="button"
                    [color]="'primary'"
                    [disabled]="!addItemForm?.valid || addItemForm?.pristine">
                    {{overrideSaveText | titlecase}}                
                </button>
            </div>
        </div>
    </form>

</div>
