/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { DashboardJobsDtoBaseResponse } from '../models/dashboard-jobs-dto-base-response';
import { JobBasicListDtoPagedResponseBaseResponse } from '../models/job-basic-list-dto-paged-response-base-response';
import { JobDtoBaseResponse } from '../models/job-dto-base-response';
import { JobFileDto } from '../models/job-file-dto';
import { JobFileDtoBaseResponse } from '../models/job-file-dto-base-response';
import { JobFileDtoListBaseResponse } from '../models/job-file-dto-list-base-response';
import { JobListDto } from '../models/job-list-dto';
import { JobListDtoBaseResponse } from '../models/job-list-dto-base-response';
import { JobListDtoListBaseResponse } from '../models/job-list-dto-list-base-response';
import { JobListDtoPagedResponseBaseResponse } from '../models/job-list-dto-paged-response-base-response';
import { JobStatusDto } from '../models/job-status-dto';
import { JobStatusDtoBaseResponse } from '../models/job-status-dto-base-response';
import { JobStatusDtoListBaseResponse } from '../models/job-status-dto-list-base-response';
import { OrderDirection } from '../models/order-direction';
import { PropertyFilter } from '../models/property-filter';

@Injectable({
  providedIn: 'root',
})
export class JobService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiJobListJobsGet
   */
  static readonly ApiJobListJobsGetPath = '/api/Job/list-jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsGet$Plain$Response(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<JobBasicListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobsGetPath, 'get');
    if (params) {
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobBasicListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsGet$Plain(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<JobBasicListDtoPagedResponseBaseResponse> {

    return this.apiJobListJobsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobBasicListDtoPagedResponseBaseResponse>) => r.body as JobBasicListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsGet$Json$Response(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<JobBasicListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobsGetPath, 'get');
    if (params) {
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobBasicListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsGet$Json(params?: {
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<JobBasicListDtoPagedResponseBaseResponse> {

    return this.apiJobListJobsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobBasicListDtoPagedResponseBaseResponse>) => r.body as JobBasicListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobListJobsForCompanyGet
   */
  static readonly ApiJobListJobsForCompanyGetPath = '/api/Job/list-jobs-for-company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobsForCompanyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsForCompanyGet$Plain$Response(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<JobListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobsForCompanyGetPath, 'get');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobsForCompanyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsForCompanyGet$Plain(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<JobListDtoPagedResponseBaseResponse> {

    return this.apiJobListJobsForCompanyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoPagedResponseBaseResponse>) => r.body as JobListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobsForCompanyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsForCompanyGet$Json$Response(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<JobListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobsForCompanyGetPath, 'get');
    if (params) {
      rb.query('CompanyId', params.CompanyId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobsForCompanyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobsForCompanyGet$Json(params?: {
    CompanyId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<JobListDtoPagedResponseBaseResponse> {

    return this.apiJobListJobsForCompanyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoPagedResponseBaseResponse>) => r.body as JobListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobAllListJobsGet
   */
  static readonly ApiJobAllListJobsGetPath = '/api/Job/all-list-jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAllListJobsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllListJobsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobAllListJobsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAllListJobsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllListJobsGet$Plain(params?: {
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobAllListJobsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAllListJobsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllListJobsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobAllListJobsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAllListJobsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllListJobsGet$Json(params?: {
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobAllListJobsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobAllDailyJobsGet
   */
  static readonly ApiJobAllDailyJobsGetPath = '/api/Job/all-daily-jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAllDailyJobsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllDailyJobsGet$Plain$Response(params?: {
    date?: string;
    searchTerm?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobAllDailyJobsGetPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('searchTerm', params.searchTerm, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAllDailyJobsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllDailyJobsGet$Plain(params?: {
    date?: string;
    searchTerm?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobAllDailyJobsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAllDailyJobsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllDailyJobsGet$Json$Response(params?: {
    date?: string;
    searchTerm?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobAllDailyJobsGetPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('searchTerm', params.searchTerm, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAllDailyJobsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAllDailyJobsGet$Json(params?: {
    date?: string;
    searchTerm?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobAllDailyJobsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobsInRangeGet
   */
  static readonly ApiJobJobsInRangeGetPath = '/api/Job/jobs-in-range';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobsInRangeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsInRangeGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobsInRangeGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobsInRangeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsInRangeGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobJobsInRangeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobsInRangeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsInRangeGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobsInRangeGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobsInRangeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsInRangeGet$Json(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobJobsInRangeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobCompletedJobsInRangeGet
   */
  static readonly ApiJobCompletedJobsInRangeGetPath = '/api/Job/completed-jobs-in-range';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobCompletedJobsInRangeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompletedJobsInRangeGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobCompletedJobsInRangeGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobCompletedJobsInRangeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompletedJobsInRangeGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobCompletedJobsInRangeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobCompletedJobsInRangeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompletedJobsInRangeGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobCompletedJobsInRangeGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobCompletedJobsInRangeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompletedJobsInRangeGet$Json(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobCompletedJobsInRangeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobsInRangeAndCategoryGet
   */
  static readonly ApiJobJobsInRangeAndCategoryGetPath = '/api/Job/jobs-in-range-and-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobsInRangeAndCategoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsInRangeAndCategoryGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
    category?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobsInRangeAndCategoryGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('category', params.category, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobsInRangeAndCategoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsInRangeAndCategoryGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
    category?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobJobsInRangeAndCategoryGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobsInRangeAndCategoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsInRangeAndCategoryGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
    category?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobsInRangeAndCategoryGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('category', params.category, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobsInRangeAndCategoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsInRangeAndCategoryGet$Json(params?: {
    startDate?: string;
    endDate?: string;
    category?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobJobsInRangeAndCategoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobCompletedJobsInRangeAndCategoryGet
   */
  static readonly ApiJobCompletedJobsInRangeAndCategoryGetPath = '/api/Job/completed-jobs-in-range-and-category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobCompletedJobsInRangeAndCategoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompletedJobsInRangeAndCategoryGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
    category?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobCompletedJobsInRangeAndCategoryGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('category', params.category, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobCompletedJobsInRangeAndCategoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompletedJobsInRangeAndCategoryGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
    category?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobCompletedJobsInRangeAndCategoryGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobCompletedJobsInRangeAndCategoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompletedJobsInRangeAndCategoryGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
    category?: string;
  }): Observable<StrictHttpResponse<JobListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobCompletedJobsInRangeAndCategoryGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('category', params.category, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobCompletedJobsInRangeAndCategoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompletedJobsInRangeAndCategoryGet$Json(params?: {
    startDate?: string;
    endDate?: string;
    category?: string;
  }): Observable<JobListDtoListBaseResponse> {

    return this.apiJobCompletedJobsInRangeAndCategoryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoListBaseResponse>) => r.body as JobListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobsDashboardMetricsGet
   */
  static readonly ApiJobJobsDashboardMetricsGetPath = '/api/Job/jobs-dashboard-metrics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobsDashboardMetricsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsDashboardMetricsGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<DashboardJobsDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobsDashboardMetricsGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardJobsDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobsDashboardMetricsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsDashboardMetricsGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<DashboardJobsDtoBaseResponse> {

    return this.apiJobJobsDashboardMetricsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardJobsDtoBaseResponse>) => r.body as DashboardJobsDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobsDashboardMetricsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsDashboardMetricsGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<DashboardJobsDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobsDashboardMetricsGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardJobsDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobsDashboardMetricsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobsDashboardMetricsGet$Json(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<DashboardJobsDtoBaseResponse> {

    return this.apiJobJobsDashboardMetricsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardJobsDtoBaseResponse>) => r.body as DashboardJobsDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobGet
   */
  static readonly ApiJobJobGetPath = '/api/Job/job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobGet$Plain$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<JobDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobGetPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobGet$Plain(params?: {
    jobId?: number;
  }): Observable<JobDtoBaseResponse> {

    return this.apiJobJobGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobDtoBaseResponse>) => r.body as JobDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobGet$Json$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<JobDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobGetPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobGet$Json(params?: {
    jobId?: number;
  }): Observable<JobDtoBaseResponse> {

    return this.apiJobJobGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobDtoBaseResponse>) => r.body as JobDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobPut
   */
  static readonly ApiJobJobPutPath = '/api/Job/job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPut$Plain$Response(params?: {
    body?: JobListDto
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPut$Plain(params?: {
    body?: JobListDto
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPut$Json$Response(params?: {
    body?: JobListDto
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPut$Json(params?: {
    body?: JobListDto
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobPost
   */
  static readonly ApiJobJobPostPath = '/api/Job/job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPost$Plain$Response(params?: {
    body?: JobListDto
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPost$Plain(params?: {
    body?: JobListDto
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPost$Json$Response(params?: {
    body?: JobListDto
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobJobPost$Json(params?: {
    body?: JobListDto
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobListItemGet
   */
  static readonly ApiJobJobListItemGetPath = '/api/Job/job-list-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobListItemGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobListItemGet$Plain$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobListItemGetPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobListItemGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobListItemGet$Plain(params?: {
    jobId?: number;
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobListItemGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobListItemGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobListItemGet$Json$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobListItemGetPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobListItemGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobListItemGet$Json(params?: {
    jobId?: number;
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobListItemGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobStatusOfJobPut
   */
  static readonly ApiJobStatusOfJobPutPath = '/api/Job/status-of-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobStatusOfJobPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobStatusOfJobPut$Plain$Response(params?: {
    jobId?: number;
    statusId?: number;
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobStatusOfJobPutPath, 'put');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('statusId', params.statusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobStatusOfJobPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobStatusOfJobPut$Plain(params?: {
    jobId?: number;
    statusId?: number;
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobStatusOfJobPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobStatusOfJobPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobStatusOfJobPut$Json$Response(params?: {
    jobId?: number;
    statusId?: number;
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobStatusOfJobPutPath, 'put');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('statusId', params.statusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobStatusOfJobPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobStatusOfJobPut$Json(params?: {
    jobId?: number;
    statusId?: number;
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobStatusOfJobPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobJobPendingPut
   */
  static readonly ApiJobJobPendingPutPath = '/api/Job/job-pending';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobPendingPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobPendingPut$Plain$Response(params?: {
    jobId?: number;
    isPending?: boolean;
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobPendingPutPath, 'put');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('isPending', params.isPending, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobPendingPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobPendingPut$Plain(params?: {
    jobId?: number;
    isPending?: boolean;
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobPendingPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJobPendingPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobPendingPut$Json$Response(params?: {
    jobId?: number;
    isPending?: boolean;
  }): Observable<StrictHttpResponse<JobListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobJobPendingPutPath, 'put');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('isPending', params.isPending, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobJobPendingPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJobPendingPut$Json(params?: {
    jobId?: number;
    isPending?: boolean;
  }): Observable<JobListDtoBaseResponse> {

    return this.apiJobJobPendingPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobListDtoBaseResponse>) => r.body as JobListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobToggleArchiveJobDelete
   */
  static readonly ApiJobToggleArchiveJobDeletePath = '/api/Job/toggle-archive-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobToggleArchiveJobDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleArchiveJobDelete$Plain$Response(params?: {
    jobId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobToggleArchiveJobDeletePath, 'delete');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobToggleArchiveJobDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleArchiveJobDelete$Plain(params?: {
    jobId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobToggleArchiveJobDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobToggleArchiveJobDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleArchiveJobDelete$Json$Response(params?: {
    jobId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobToggleArchiveJobDeletePath, 'delete');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobToggleArchiveJobDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleArchiveJobDelete$Json(params?: {
    jobId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobToggleArchiveJobDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobToggleAcceptJobPost
   */
  static readonly ApiJobToggleAcceptJobPostPath = '/api/Job/toggle-accept-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobToggleAcceptJobPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleAcceptJobPost$Plain$Response(params?: {
    jobId?: number;
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobToggleAcceptJobPostPath, 'post');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobToggleAcceptJobPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleAcceptJobPost$Plain(params?: {
    jobId?: number;
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobToggleAcceptJobPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobToggleAcceptJobPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleAcceptJobPost$Json$Response(params?: {
    jobId?: number;
    userId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobToggleAcceptJobPostPath, 'post');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobToggleAcceptJobPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleAcceptJobPost$Json(params?: {
    jobId?: number;
    userId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobToggleAcceptJobPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobToggleDeclineJobPost
   */
  static readonly ApiJobToggleDeclineJobPostPath = '/api/Job/toggle-decline-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobToggleDeclineJobPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleDeclineJobPost$Plain$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobToggleDeclineJobPostPath, 'post');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobToggleDeclineJobPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleDeclineJobPost$Plain(params?: {
    jobId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobToggleDeclineJobPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobToggleDeclineJobPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleDeclineJobPost$Json$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobToggleDeclineJobPostPath, 'post');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobToggleDeclineJobPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobToggleDeclineJobPost$Json(params?: {
    jobId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobToggleDeclineJobPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobCompleteJobPost
   */
  static readonly ApiJobCompleteJobPostPath = '/api/Job/Complete-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobCompleteJobPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompleteJobPost$Plain$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobCompleteJobPostPath, 'post');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobCompleteJobPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompleteJobPost$Plain(params?: {
    jobId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobCompleteJobPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobCompleteJobPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompleteJobPost$Json$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobCompleteJobPostPath, 'post');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobCompleteJobPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCompleteJobPost$Json(params?: {
    jobId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobCompleteJobPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobListJobStatusesGet
   */
  static readonly ApiJobListJobStatusesGetPath = '/api/Job/list-Job-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobStatusesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobStatusesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<JobStatusDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobStatusesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobStatusDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobStatusesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobStatusesGet$Plain(params?: {
  }): Observable<JobStatusDtoListBaseResponse> {

    return this.apiJobListJobStatusesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobStatusDtoListBaseResponse>) => r.body as JobStatusDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobListJobStatusesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobStatusesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<JobStatusDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobListJobStatusesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobStatusDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobListJobStatusesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobListJobStatusesGet$Json(params?: {
  }): Observable<JobStatusDtoListBaseResponse> {

    return this.apiJobListJobStatusesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobStatusDtoListBaseResponse>) => r.body as JobStatusDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobAddJobStatusPost
   */
  static readonly ApiJobAddJobStatusPostPath = '/api/Job/add-job-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAddJobStatusPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobAddJobStatusPost$Plain$Response(params?: {
    body?: JobStatusDto
  }): Observable<StrictHttpResponse<JobStatusDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobAddJobStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobStatusDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAddJobStatusPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobAddJobStatusPost$Plain(params?: {
    body?: JobStatusDto
  }): Observable<JobStatusDtoBaseResponse> {

    return this.apiJobAddJobStatusPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobStatusDtoBaseResponse>) => r.body as JobStatusDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAddJobStatusPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobAddJobStatusPost$Json$Response(params?: {
    body?: JobStatusDto
  }): Observable<StrictHttpResponse<JobStatusDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobAddJobStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobStatusDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAddJobStatusPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobAddJobStatusPost$Json(params?: {
    body?: JobStatusDto
  }): Observable<JobStatusDtoBaseResponse> {

    return this.apiJobAddJobStatusPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobStatusDtoBaseResponse>) => r.body as JobStatusDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobUpdateJobStatusPut
   */
  static readonly ApiJobUpdateJobStatusPutPath = '/api/Job/update-job-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobUpdateJobStatusPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateJobStatusPut$Plain$Response(params?: {
    body?: JobStatusDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobUpdateJobStatusPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobUpdateJobStatusPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateJobStatusPut$Plain(params?: {
    body?: JobStatusDto
  }): Observable<BooleanBaseResponse> {

    return this.apiJobUpdateJobStatusPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobUpdateJobStatusPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateJobStatusPut$Json$Response(params?: {
    body?: JobStatusDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobUpdateJobStatusPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobUpdateJobStatusPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateJobStatusPut$Json(params?: {
    body?: JobStatusDto
  }): Observable<BooleanBaseResponse> {

    return this.apiJobUpdateJobStatusPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobArchiveJobStatusDelete
   */
  static readonly ApiJobArchiveJobStatusDeletePath = '/api/Job/archive-job-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobArchiveJobStatusDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobArchiveJobStatusDelete$Plain$Response(params?: {
    statusId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobArchiveJobStatusDeletePath, 'delete');
    if (params) {
      rb.query('statusId', params.statusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobArchiveJobStatusDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobArchiveJobStatusDelete$Plain(params?: {
    statusId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobArchiveJobStatusDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobArchiveJobStatusDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobArchiveJobStatusDelete$Json$Response(params?: {
    statusId?: number;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobArchiveJobStatusDeletePath, 'delete');
    if (params) {
      rb.query('statusId', params.statusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobArchiveJobStatusDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobArchiveJobStatusDelete$Json(params?: {
    statusId?: number;
  }): Observable<BooleanBaseResponse> {

    return this.apiJobArchiveJobStatusDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobGetFilesGet
   */
  static readonly ApiJobGetFilesGetPath = '/api/Job/get-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobGetFilesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobGetFilesGet$Plain$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<JobFileDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobGetFilesGetPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobFileDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobGetFilesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobGetFilesGet$Plain(params?: {
    jobId?: number;
  }): Observable<JobFileDtoListBaseResponse> {

    return this.apiJobGetFilesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobFileDtoListBaseResponse>) => r.body as JobFileDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobGetFilesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobGetFilesGet$Json$Response(params?: {
    jobId?: number;
  }): Observable<StrictHttpResponse<JobFileDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobGetFilesGetPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobFileDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobGetFilesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobGetFilesGet$Json(params?: {
    jobId?: number;
  }): Observable<JobFileDtoListBaseResponse> {

    return this.apiJobGetFilesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobFileDtoListBaseResponse>) => r.body as JobFileDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobUploadFileJobIdPost
   */
  static readonly ApiJobUploadFileJobIdPostPath = '/api/Job/upload-file/{jobId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobUploadFileJobIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiJobUploadFileJobIdPost$Plain$Response(params: {
    jobId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<JobFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobUploadFileJobIdPostPath, 'post');
    if (params) {
      rb.path('jobId', params.jobId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobUploadFileJobIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiJobUploadFileJobIdPost$Plain(params: {
    jobId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<JobFileDtoBaseResponse> {

    return this.apiJobUploadFileJobIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobFileDtoBaseResponse>) => r.body as JobFileDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobUploadFileJobIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiJobUploadFileJobIdPost$Json$Response(params: {
    jobId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<JobFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobUploadFileJobIdPostPath, 'post');
    if (params) {
      rb.path('jobId', params.jobId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobUploadFileJobIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiJobUploadFileJobIdPost$Json(params: {
    jobId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<JobFileDtoBaseResponse> {

    return this.apiJobUploadFileJobIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobFileDtoBaseResponse>) => r.body as JobFileDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobUpdateFilePost
   */
  static readonly ApiJobUpdateFilePostPath = '/api/Job/update-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobUpdateFilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateFilePost$Plain$Response(params?: {
    body?: JobFileDto
  }): Observable<StrictHttpResponse<JobFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobUpdateFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobUpdateFilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateFilePost$Plain(params?: {
    body?: JobFileDto
  }): Observable<JobFileDtoBaseResponse> {

    return this.apiJobUpdateFilePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobFileDtoBaseResponse>) => r.body as JobFileDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobUpdateFilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateFilePost$Json$Response(params?: {
    body?: JobFileDto
  }): Observable<StrictHttpResponse<JobFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobUpdateFilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobUpdateFilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiJobUpdateFilePost$Json(params?: {
    body?: JobFileDto
  }): Observable<JobFileDtoBaseResponse> {

    return this.apiJobUpdateFilePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobFileDtoBaseResponse>) => r.body as JobFileDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiJobDownloadFileFileIdGet
   */
  static readonly ApiJobDownloadFileFileIdGetPath = '/api/Job/download-file/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobDownloadFileFileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobDownloadFileFileIdGet$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobDownloadFileFileIdGetPath, 'get');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobDownloadFileFileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobDownloadFileFileIdGet(params: {
    fileId: number;
  }): Observable<string> {

    return this.apiJobDownloadFileFileIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiJobDeleteFileFileIdDelete
   */
  static readonly ApiJobDeleteFileFileIdDeletePath = '/api/Job/delete-file/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobDeleteFileFileIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobDeleteFileFileIdDelete$Plain$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<JobFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobDeleteFileFileIdDeletePath, 'delete');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobDeleteFileFileIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobDeleteFileFileIdDelete$Plain(params: {
    fileId: number;
  }): Observable<JobFileDtoBaseResponse> {

    return this.apiJobDeleteFileFileIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<JobFileDtoBaseResponse>) => r.body as JobFileDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobDeleteFileFileIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobDeleteFileFileIdDelete$Json$Response(params: {
    fileId: number;
  }): Observable<StrictHttpResponse<JobFileDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, JobService.ApiJobDeleteFileFileIdDeletePath, 'delete');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobFileDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobDeleteFileFileIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobDeleteFileFileIdDelete$Json(params: {
    fileId: number;
  }): Observable<JobFileDtoBaseResponse> {

    return this.apiJobDeleteFileFileIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<JobFileDtoBaseResponse>) => r.body as JobFileDtoBaseResponse)
    );
  }

}
